/* .card */
//@media ($xs) { /* .card $xs */ }
//@media ($sm) { /* .card $sm */ }
//@media ($md) { /* .card $md */ }
//@media ($lg) { /* .card $lg */ }
//@media ($xl) { /* .card $xl */ }
//@media ($xxl) { /* .card $xxl */ }

.card {
  background-color: #F9FAFB;
  // background-color: #f00;
  display: flex;
  flex-direction: column;
  padding: 24px;
  text-decoration: none;
  color: #5C6471;
  border-radius: 8px;
  transition: all ease-in-out 300ms;
  margin-bottom: 5px;
  height: 100%;
  font-family: $ff_base;
  font-size: 14px;
  line-height: 20px;

  &:hover {
    box-shadow: 0px 0px 2px 2px rgba(150, 150, 150, 0.2);
    text-decoration: none;
  }

  &__top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  &__title {
    margin-bottom: 8px;
  }

  &__desc {
    color: #0787E4;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
  }

  &__body {
    margin-bottom: 36px;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__price {
    font-family: $ff_third;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -1px;
    font-weight: 700;
    white-space: nowrap;
    color: $c_text;
  }

  &__price span {
    font-family: $ff_base;
    font-size: 14px;
    line-height: 16px;
    color: #5C6471;
    font-weight: 400;
    letter-spacing: 0;
  }

  &__link {
    text-transform: uppercase;
    font-family: $ff_third;
    position: relative;
    font-weight: 700;
    font-size: 11px;
    line-height: 24px;
    padding-right: 20px;
  }

  &__link:before {
    position: absolute;
    top: 6px;
    right: 5px;
  }

  // &__view {
  //   width: 60px;
  //   min-width: 60px;
  //   margin-right: 8px;
  // }

  // &__body {
  //   flex-grow: 1;
  //   word-break: break-word;
  //   display: flex;
  //   flex-direction: column;
  // }

  // &__title {
  //   margin-top: 0;
  //   font-size: 20px;
  //   line-height: 24px;
  //   margin-bottom: 8px;
  //   font-family: $ff_second;
  //   font-weight: 700;
  //   color: #0F1011;
  // }

  // &__data {
  //   margin-bottom: 16px;
  // }

  // &__ingredient {
  //   color: #5C6471;
  //   font-size: 14px;
  //   line-height: 16px;
  //   font-family: $ff_second;
  // }

  // &__dosage {
  //   font-size: 12px;
  //   line-height: 14px;
  // }

  // &__desc {
  //   font-size: 14px;
  //   line-height: 20px;
  //   color: #7C828B;
  //   margin-bottom: 20px;
  // }

  // &__footer {
  //   display: flex;
  //   flex-wrap: wrap;
  //   align-items: center;
  //   margin-top: auto;
  // }

  // &__price {
  //   margin-right: 5px;
  //   font-size: 24px;
  //   line-height: 28px;
  //   font-weight: 700;
  //   font-family: $ff_second;
  //   letter-spacing: -1px;
  // }

  // .btn {
  //   margin-left: auto;
  // }
}