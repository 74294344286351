/* .slider */
//@media ($xs) { /* .slider $xs */ }
//@media ($sm) { /* .slider $sm */ }
//@media ($md) { /* .slider $md */ }
//@media ($lg) { /* .slider $lg */ }
//@media ($xl) { /* .slider $xl */ }
//@media ($xxl) { /* .slider $xxl */ }

.slider-wrap {
  padding: 125px 16px 53px;
  color: rgba(251, 251, 251, 0.8);
  background: radial-gradient(131.98% 131.37% at 38.84% -31.37%, rgba(140, 227, 255, 0.8) 0%, rgba(29, 163, 232, 0.8) 100%);
  margin-left: -16px;
  margin-right: -16px;
  position: relative;

  @media ($lg) {
    backdrop-filter: blur(4px);
    max-width: 784px;
    margin: 0 auto;
    padding: 56px 100px 109px;
    border-radius: 16px;
    background: radial-gradient(131.98% 131.37% at 38.84% -31.37%, rgba(140, 227, 255, 0.8) 0%, rgba(29, 163, 232, 0.8) 100%);
  }

  &__ctrl {
    display: flex;
    justify-content: center;
  }

  &__btn {
    position: static;
    margin-top: 0;
    width: 48px;
    height: 48px;
    background-color: #FAFAFA;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    box-shadow: 0px 6px 16px rgba(34, 46, 75, 0.16);

    @media ($lg) {
      position: absolute;
      top: 50%;
      margin-top: -24px;
    }
  }

  &__btn:hover {
    box-shadow: 0px 6px 16px rgba(34, 46, 75, 0.56);
    background-color: #fff;
  }

  &__btn:after {
    display: none;
  }

  &__btn:last-child {
    @media ($lg) {
      right: -24px;
    }
  }

  &__btn:last-child:before {
    margin-right: -4px;
  }

  &__btn:first-child {
    margin-right: 19px;

    @media ($lg) {
      left: -24px;
      margin-right: 0;
    }
  }

  &__btn:first-child:before {
    margin-left: -4px;
  }

  &__pagination {
    display: none;
    position: absolute;
    top: 100%;
    text-align: center;

    @media ($lg) {
      display: block;
    }
  }

  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    margin: 10px 3px !important;
    background-color: #C3C0C0;
    opacity: 0.5;
  }

  .swiper-pagination-bullet-active {
    background-color: #20A5E9;
    opacity: 1;
  }
}

.slider {
  overflow: hidden;

  @media ($lg) {
    //background: none;
    //margin-left: 0;
    //margin-right: 0;
    //padding: 0;
  }

  &__title {
    font-family: $ff_third;
    font-size: 32px;
    line-height: 36px;
    color: #fff;
  }

  &__text {
    margin-bottom: 94px;

    @media ($lg) {
      margin-bottom: 0;
    }
  }
}