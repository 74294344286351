/* .about-product */
//@media ($xs) { /* .about-product $xs */ }
//@media ($sm) { /* .about-product $sm */ }
//@media ($md) { /* .about-product $md */ }
@media ($lg) { /* .about-product $lg */ }
//@media ($xl) { /* .about-product $xl */ }
//@media ($xxl) { /* .about-product $xxl */ }

.about-product {
  margin-bottom: 40px;
  
  &__title {
    margin-bottom: 36px;
  }

  &__item {
    margin-bottom: 28px;
  }

  &__item:last-child {
    margin-bottom: 0;
  }

  p {
    margin-bottom: 28px;
  }

  p:last-child {
    margin-bottom: 0;
  }

  .custom-list {
    margin: 28px 0;
  }
}