/* .review */
//@media ($xs) { /* .review $xs */ }
//@media ($sm) { /* .review $sm */ }
//@media ($md) { /* .review $md */ }
@media ($lg) { /* .review $lg */ }
//@media ($xl) { /* .review $xl */ }
//@media ($xxl) { /* .review $xxl */ }

.review {
  background-color: #FFE151;
  border-radius: 20px;
  padding: 32px;
  display: flex;
  justify-content: center;

  &__inside {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ($lg) {
      flex-direction: row;
      max-width: 634px;
    }
  }

  &__view {
    width: 128px;
    height: 128px;
    min-width: 128px;
    min-height: 128px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 84px;

    @media ($lg) {
      margin-right: 24px;
    }
  }

  &__img {
    min-width: 100%;
    min-height: 100%;
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ($lg) {
      align-items: flex-start;
    }
  }

  &__rating {
    display: flex;
    margin-bottom: 24px;
  }

  &__rating-item {
    width: 30px;
    height: 30px;
    background: url("../img/star.png") no-repeat 50% 50%;
    cursor: pointer;

    &:hover {
      opacity: .7;
    }
  }

  &__text {
    text-align: center;
    margin-bottom: 24px;

    @media ($lg) {
      text-align: left;
    }
  }

  &__author {
    color: #0F1011;
    font-weight: 700;
  }
}