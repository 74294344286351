/* .payments */
//@media ($xs) { /* .payments $xs */ }
//@media ($sm) { /* .payments $sm */ }
//@media ($md) { /* .payments $md */ }
//@media ($lg) { /* .payments $lg */ }
//@media ($xl) { /* .payments $xl */ }
//@media ($xxl) { /* .payments $xxl */ }

.payments {
  text-align: center;

  &--footer {
    margin-bottom: 20px;
  }

  &__item {
    margin-right: 33px;
  }

  &__item:last-child {
    margin-right: 0;
  }
}
