/* .banner */
//@media ($xs) { /* .banner $xs */ }
//@media ($sm) { /* .banner $sm */ }
//@media ($md) { /* .banner $md */ }
@media ($lg) { /* .banner $lg */ }
//@media ($xl) { /* .banner $xl */ }
//@media ($xxl) { /* .banner $xxl */ }

.banner {
  background: url("../img/banner.jpg")no-repeat 100% 0;
  background-size: auto 100%;
  padding-top: 90px;
  padding-bottom: 106px;
  text-align: center;
  margin-right: -16px;
  margin-left: -16px;

  @media ($lg) {
    background-size: cover;
    border-radius: 24px;
    padding-top: 108px;
    margin-right: 0;
    margin-left: 0;
  }

  &__text {
    color: rgba(251, 251, 251, 0.8);
    font-size: 28px;
    line-height: 30px;

    @media ($lg) {
      margin-bottom: 8px;
    }
  }
  &__title {
    font-size: 64px;
    line-height: 96px;
    letter-spacing: -1px;
    font-family: $ff_second;
    color: #fff;
    font-weight: 700;
    margin-bottom: 48px;

    @media ($lg) {
      font-size: 96px;
      line-height: 1;
    }
  }
  //&__link {
  //  margin-bottom: 106px;
  //}
  .btn {
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    padding: 19px 32px;
    border-radius: 38px;
  }
}