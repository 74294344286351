/* .ctrl-header {} */
//@media ($xs) { /* .ctrl-header {} $xs */ }
//@media ($sm) { /* .ctrl-header {} $sm */ }
//@media ($md) { /* .ctrl-header {} $md */ }
@media ($lg) { /* .ctrl-header {} $lg */ }
//@media ($xl) { /* .ctrl-header {} $xl */ }
//@media ($xxl) { /* .ctrl-header {} $xxl */ }

.ctrl-header {
  background-color: #fff;
  margin-left: -16px;
  margin-right: -16px;
  padding: 24px 28px;

  @media ($lg) {
    background-color: transparent;
  }
}