/* .form */
//@media ($xs) { /* .form $xs */ }
//@media ($sm) { /* .form $sm */ }
//@media ($md) { /* .form $md */ }
//@media ($lg) { /* .form $lg */ }
//@media ($xl) { /* .form $xl */ }
//@media ($xxl) { /* .form $xxl */ }

.form {
  &--contact {
    margin-bottom: 48px;

    @media ($lg) {
      margin-bottom: 0;
    }
  }
  &--contact small {
    color: $c_text;
  }
  &--contact .btn {
    font-size: 20px;
    line-height: 24px;
    padding: 20px 32px;
    border-radius: 12px;
  }
  &__row {
    display: flex;
  }
  .tabs__item-credit-card &__row:last-child .form__desc {
    padding-top: 16px;
    font-size: 14px;
    line-height: 20px;
    color: #787B82;
    text-decoration: underline;
  }
  .tabs__item-credit-card &__row:last-child .form__desc:hover {
    text-decoration: none;
  }
  &__row--wrap {
    flex-wrap: wrap;
  }
  &__row-label {
    font-size: 12px;
    margin-top: -3px;
    margin-bottom: 3px;
  }
  &__field--lbl .form__input:focus + .form__lbl-input,
  &__field--lbl .form__textarea:focus + .form__lbl-input,
  &__field--lbl.active .form__input + .form__lbl-input,
  &__field--lbl.active .form__textarea + .form__lbl-input {
    color: #5c6471;
    position: absolute;
    left: 16px;
    font-size: 11px;
    pointer-events: none;
    align-items: flex-start;
  }
  &__field {
    flex-grow: 1;
    margin-right: 10px;
    overflow: hidden;
    margin-bottom: 12px;
  }
  &__field:last-child {
    margin-right: 0;
  }
  &__block-capcha {
    margin-bottom: 12px;
  }
  &__reload-img {
    color: #5C6471;
    font-size: 14px;
    line-height: 20px;
    text-decoration: underline;
    font-family: $ff_third;
  }
  &__reload-img:hover {
    text-decoration: none;
  }
  &__capcha {
    margin-right: 12px;
  }
  &__field--112,
  &__field--118,
  &__field--198,
  &__field--211 {
    flex-grow: 0;
    width: 100%;
  }
  &__field--md-136,
  &__field--md-140,
  &__field--md-149,
  &__field--md-220 {
    @media ($md) {
      flex-grow: 0;
      width: 100%;
    }
  }
  &__field--112 {
    min-width: 112px;
    max-width: 112px;
  }
  &__field--118 {
    min-width: 118px;
    max-width: 118px;
  }
  &__field--198 {
    min-width: 198px;
    max-width: 198px;
  }
  &__field--211 {
    min-width: 211px;
    max-width: 211px;
  }
  &__field--md-136 {
    @media ($md) {
      min-width: 136px;
      max-width: 136px;
    }
  }
  &__field--md-140 {
    @media ($md) {
      min-width: 140px;
      max-width: 140px;
    }
  }
  &__field--md-149 {
    @media ($md) {
      min-width: 149px;
      max-width: 149px;
    }
  }
  &__field--md-220 {
    @media ($md) {
      min-width: 220px;
      max-width: 220px;
    }
  }
  &__field--lbl {
    position: relative;
  }
  &__input,
  &__textarea {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 4px;
    border: 1px solid #C3C8D0;
    outline: none;
  }
  &__input {
    height: 56px;
  }
  &__textarea {
    height: 192px;
    resize: none;
    display: block;
  }
  &__field--lbl .form__input,
  &__field--lbl .form__textarea {
    transition: all ease 0.3s;
    color: transparent;
  }
  &__field--lbl .form__input:focus,
  &__field--lbl .form__textarea:focus {
    border-color: $c_brand;
    box-shadow: 0px 2px 12px rgba(249, 235, 114, 0.5);
  }
  &__field--lbl .form__input:focus,
  &__field--lbl .form__textarea:focus,
  &__field--lbl.active .form__input,
  &__field--lbl.active .form__textarea {
    padding-top: 20px;
    color: #202225;
  }
  &__lbl-input {
    position: absolute;
    left: 16px;
    font-size: 16px;
    pointer-events: none;
    transition: all ease 0.3s;
    color: #C7CCD8;
    top: 3px;
    line-height: 1.2;
    align-items: center;
    height: 46px;
    display: flex;
  }
  &__field--select {
    position: relative;
  }
  &__select {
    width: 100%;
    height: 56px;
    appearance: none;
    padding-left: 15px;
    padding-right: 30px;
    border-radius: 4px;
    color: #BAC0C9;
    border: 1px solid #C3C8D0;
    outline: none;
  }
  &__option {
    color: $c_text;
  }
  &__field--select:after {
    content: "";
    position: absolute;
    top: 26px;
    right: 16px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 4px 0 4px;
    border-color: #1F2125 transparent transparent transparent;
    pointer-events: none;
  }
  &__error {
    color: #FB540D;
    padding-top: 8px;
    font-size: 14px;
    line-height: 16px;
  }
}
