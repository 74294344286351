/* .shopping-footer */
//@media ($xs) { /* .shopping-footer $xs */ }
//@media ($sm) { /* .shopping-footer $sm */ }
@media ($md) { /* .shopping-footer $md */ }
@media ($lg) { /* .shopping-footer $lg */ }
//@media ($xl) { /* .shopping-footer $xl */ }
//@media ($xxl) { /* .shopping-footer $xxl */ }

.shopping-footer {
  border-top: 1px solid #D2D4D9;
  padding-top: 24px;

  @media ($lg) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 31px;
  }

  &__total {
    text-align: center;
    font-family: $ff_second;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    letter-spacing: -1px;
    margin-bottom: 16px;

    @media ($md) {
      margin-bottom: 0;
      margin-right: 32px;
      font-size: 20px;
      line-height: 24px;
    }
  }

  &__btn {
    text-align: center;
  }

  &__submit {
    margin-bottom: 16px;

    @media ($md) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &__ssl {
    text-align: center;

    @media ($lg) {
      text-align: left;
      max-width: 484px;
    }
  }
  &__ssl-list {
    margin-bottom: 12px;

    @media ($lg) {
      margin-bottom: 14px;
    }
  }
  &__ssl-list img {
    height: 20px;

    @media ($md) {
      height: 28px;
    }
  }
  &__ssl-list img:first-child {
    margin-right: 25px;
  }
  &__ssl-desc {
    font-size: 12px;
    line-height: 16px;
    color: #5C6471;
    padding: 0 5px;
  }

  .btn {
    width: 100%;
    max-width: 328px;
    text-align: center;
    padding: 19px;

    @media ($md) {
      padding: 19px 39px;
    }
  }
}