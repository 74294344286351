/* .ctrl-select */
//@media ($xs) { /* .ctrl-select $xs */ }
//@media ($sm) { /* .ctrl-select $sm */ }
//@media ($md) { /* .ctrl-select $md */ }
@media ($lg) { /* .ctrl-select $lg */ }
//@media ($xl) { /* .ctrl-select $xl */ }
//@media ($xxl) { /* .ctrl-select $xxl */ }

.ctrl-select {
  display: flex;
  flex-wrap: wrap;

  @media ($lg) {
    margin-right: 41px;
  }

  &__item {
    position: relative;
    width: 58%;

    @media ($lg) {
      width: auto;
      margin-right: 9px;
    }
  }

  &__item:first-child {
    width: 100%;
    margin-bottom: 13px;

    @media ($lg) {
      width: auto;
      margin-bottom: 0;
    }
  }

  &__item:last-child {
    width: 36%;
    margin-left: 6%;

    @media ($lg) {
      width: auto;
      margin-left: 0;
      margin-right: 0;
    }
  }

  &__item:before {
    position: absolute;
    top: 15px;
    right: 11px;
    pointer-events: none;
  }

  &__item:last-child {
    margin-right: 0;
  }

  &__field {
    appearance: none;
    padding: 7px 30px 7px 12px;
    outline: none;
    border: none;
    cursor: pointer;
    font-size: 14px;
    line-height: 20px;
    background-color: rgba(0, 0, 0, .2);
    border-radius: 6px;
    color: #fff;
    font-family: inherit;
    width: 100%;

    @media ($lg) {
      padding-left: 12px;
    }
  }
  &__option {
    background-color: #fff;
    color: $c_text;
  }
}