/* .cart */
//@media ($xs) { /* .cart $xs */ }
//@media ($sm) { /* .cart $sm */ }
//@media ($md) { /* .cart $md */ }
//@media ($lg) { /* .cart $lg */ }
//@media ($xl) { /* .cart $xl */ }
//@media ($xxl) { /* .cart $xxl */ }

.cart {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  color: $c_text;
  font-size: 18px;
  line-height: 24px;
  //margin-bottom: 16px;

  @media (&lg) {
    color: #fff;
  }

  &:hover {
    text-decoration: none;
  }

  &__icon {
    margin-right: 18px;
    background-color: #fff;
    width: 56px;
    height: 56px;
    border-radius: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 16px rgba(35, 71, 88, 0.5);
    position: relative;
  }

  .ctrl-header &__icon {
    background: linear-gradient(50.26deg, rgba(198, 208, 228, 0.8) 12.82%, rgba(229, 236, 249, 0) 86.2%), #FFFFFF;
    box-shadow: 0px 3.42857px 13.7143px rgba(35, 71, 88, 0.2);
  }

  &__icon:hover {
    opacity: .7;
  }

  &__count {
    position: absolute;
    top: 8px;
    right: 0;
    font-family: $ff_third;
    color: #F5F5F5;
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
    background-color: #FB540D;
    display: inline-block;
    padding: 2px 8px;
    border-radius: 9px;
  }

  &__count:empty {
    display: none;
  }

  &__body {
    flex-grow: 1;
    margin-right: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__title {
    font-size: 14px;
    line-height: 16px;
    color: #5C6471;

    @media ($lg) {
      color: rgba(251, 251, 251, 0.8);
    }
  }

  &__amount {
    font-weight: 700;

    @media ($lg) {
      color: #fff;
    }
  }
}