.bonuses {
   margin-bottom: 48px;

  &__item {
    padding: 32px 100px 36px 24px;
    margin-bottom: 12px;
    border-radius: 12px;
    position: relative;
    overflow: hidden;

    @media ($lg) {
      padding-right: 157px;
    }
  }

  &__item:last-child {
    margin-bottom: 0;
  }

  &__item:after {
    content: "";
    position: absolute;
    max-height: 100%;
  }

  &__title {
    font-size: 20px;
    line-height: 24px;
    font-family: $ff_third;
    margin-bottom: 8px;
  }

  &__text {
    font-size: 14px;
    line-height: 20px;
  }

  &__item--free-shipping {
    background: #11528E;
    min-height: 160px;
  }

  &__item--free-shipping:after {
    width: 162px;
    height: 160px;
    background: url("../img/bonus-free-shipping.png") no-repeat 0 100%;
    bottom: 0;
    right: 0;
    
    @media ($lg) {
      width: 214px;
    }
  }
  
  &__item--free-shipping .bonuses__title,
  &__item--bonus-pills .bonuses__title {
    color: #fff;
  }
  
  &__item--free-shipping .bonuses__text,
  &__item--bonus-pills .bonuses__text {
    color: #FBFBFBCC;
  }
  
  &__item--secure-ordering {
    background-color: #E1E6E9;
    min-height: 176px;
  }

  &__item--secure-ordering:after {
    width: 116px;
    height: 166px;
    background: url("../img/bonus-secure-ordering.png") no-repeat 0 100%;
    bottom: 10px;
    right: 0;
    
    @media ($lg) {
      width: 166px;
      right: 6px;
    }
  }
  
  &__item--secure-ordering .bonuses__title,
  &__item--secure-ordering .bonuses__text {
    color: #000;
  }

  &__item--bonus-pills {
    background-color: #28C898;
    min-height: 160px;
  }

  &__item--bonus-pills:after {
    width: 90px;
    height: 118px;
    background: url("../img/bonus-pills.png") no-repeat 0 100%;
    bottom: 19px;
    right: 0;
    
    @media ($lg) {
      width: 118px;
      right: 27px;
    }
  }
}