/* .billing-address */
//@media ($xs) { /* .billing-address $xs */ }
//@media ($sm) { /* .billing-address $sm */ }
@media ($md) { /* .billing-address $md */ }
//@media ($lg) { /* .billing-address $lg */ }
@media ($xl) { /* .billing-address $xl */ }
//@media ($xxl) { /* .billing-address $xxl */ }

.billing-address {
  margin-bottom: 32px;

  @media ($xl) {
    max-width: 452px;
  }

  &__ordered-before .form__field {
    margin-bottom: 16px;
  }
  .form__field {
    margin-bottom: 8px;
  }
  .checkbox {
    @media ($md) {
      margin-bottom: 8px;
    }
  }
}