/* .certificates */
//@media ($xs) { /* .certificates $xs */ }
//@media ($sm) { /* .certificates $sm */ }
//@media ($md) { /* .certificates $md */ }
//@media ($lg) { /* .certificates $lg */ }
//@media ($xl) { /* .certificates $xl */ }
//@media ($xxl) { /* .certificates $xxl */ }

.certificates {
  text-align: center;
  margin-bottom: 20px;

  &__item {
    margin-right: 18px;
  }

  &__item:last-child {
    margin-right: 0;
  }
}