/* .blister */
//@media ($xs) { /* .blister $xs */ }
//@media ($sm) { /* .blister $sm */ }
// @media ($md) { /* .blister $md */ }
//@media ($lg) { /* .blister $lg */ }
//@media ($xl) { /* .blister $xl */ }
//@media ($xxl) { /* .blister $xxl */ }

.blister {
  margin-bottom: 40px;

  @media ($lg) {
    margin-bottom: 48px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 16px;
  }

  &__title {
    font-family: $ff_third;
    font-size: 32px;
    line-height: 36px;
    letter-spacing: -1px;
    font-weight: 700;
    color: $c_text;
  }

  &__title span {
    display: block;
    color: #BAC0C9;
    font-size: 20px;
    line-height: 24px;
  }

  &__view {
    display: flex;
    align-items: center;
  }

  &__item {
    margin-bottom: 28px;
  }

  &__item:last-child {
    margin-bottom: 0;
  }

  &__item-title {
    margin-top: 0;
    margin-bottom: 8px;
    font-size: 20px;
    line-height: 24px;
    font-family: $ff_third;
    color: #0F1011;
  }

  &__item-text a,
  &__item-text span {
    display: inline-block;
  }

  &__item-text a:after,
  &__item-text span:after {
    content: '/';
    display: inline-block;
    margin: 0 4px;
    color: #BAC0C9;
  }

  &__item-text a:last-child:after,
  &__item-text span:last-child:after {
    display: none;
  }
}