/* .bestseller */
//@media ($xs) { /* .bestseller $xs */ }
//@media ($sm) { /* .bestseller $sm */ }
//@media ($md) { /* .bestseller $md */ }
//@media ($lg) { /* .bestseller $lg */ }
//@media ($xl) { /* .bestseller $xl */ }
@media ($xxl) { /* .bestseller $xxl */ }

.bestseller {
  position: relative;

  &__list {
    flex-wrap: nowrap;
    overflow-x: auto;
  }

  &__item {
    min-width: 290px;
  }

  &__btn {
    width: 48px;
    height: 48px;
    background: #FAFAFA;
    box-shadow: 0px 6px 16px rgba(34, 46, 75, 0.16);
    border-radius: 24px;
    display: none;

    &:hover {
      box-shadow: 0px 6px 16px rgba(34, 46, 75, 0.36);
    }

    @media ($lg) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__btn:after {
    display: none;
  }

  &__btn.swiper-button-prev {
    @media ($xxl) {
      left: -24px;
    }
  }

  &__btn.swiper-button-next {
    @media ($xxl) {
      right: -24px;
    }
  }

  &__btn.swiper-button-prev:before {
    transform: rotate(180deg);
    margin-left: -4px;
  }

  &__btn.swiper-button-next:before {
    margin-right: -4px;
  }
}