.footer {
    //position: relative;

    &__top-line {
        background-color: #EFF5F6;
        padding-top: 48px;
        padding-bottom: 40px;

        @media ($lg) {
            padding-top: 72px;
            padding-bottom: 72px;
        }
    }

    &__top-item {
        margin-bottom: 32px;
    }

    .col-lg-4:last-child .footer__top-item {
        margin-bottom: 0;
    }

    &__title {
        font-family: $ff_second;
        color: #5C6471;
        font-size: 17px;
        line-height: 20px;
        letter-spacing: 0.5px;
        font-weight: 700;
        margin-bottom: 16px;
    }

    &__list {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &__list-item {
        margin-bottom: 16px;
        color: #0F1011;
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
        font-family: $ff_third;
    }

    &__list-item:last-child {
        margin-bottom: 0;
    }

    &__list-item img {
        margin-left: 4px;
        margin-top: -2px;
    }

    &__list-item a {
        color: #0F1011;
    }
    
    &__bottom-line {
        background-color: #191B1D;
        color: #7C828B;
        padding-top: 24px;
        padding-bottom: 118px;
        text-align: center;

        @media ($lg) {
            padding-top: 27px;
            padding-bottom: 30px;
        }
    }

    &__bottom-line .row {
        @media ($lg) {
            flex-direction: row-reverse;
        }
    }

    &__bottom-item {
        margin-bottom: 20px;

        @media ($lg) {
            margin-bottom: 0;
            text-align: right;
        }
    }

    .col-lg-4:last-child .footer__bottom-item {
        margin-bottom: 0;
    }

    &__bottom-item--copyright {
        font-size: 10px;
        line-height: 12px;

        @media ($lg) {
            text-align: left;
            display: flex;
            align-items: flex-start;
        }
    }

    &__bottom-item--copyright img {
        display: block;
        margin: 0 auto 4px;

        @media ($lg) {
            margin: 0 4px 0 0;
        }
    }

    &__bottom-item--payments {
        display: flex;
        justify-content: center;
        align-items: flex-end;

        @media ($lg) {
            text-align: center;
        }
    }

    &__bottom-item--payments img {
        margin-right: 33px;
    }

    &__bottom-item--payments img:last-child {
        margin-right: 0;
    }
}
