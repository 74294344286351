/* .mob-products */
//@media ($xs) { /* .mob-products $xs */ }
//@media ($sm) { /* .mob-products $sm */ }
//@media ($md) { /* .mob-products $md */ }
//@media ($lg) { /* .mob-products $lg */ }
//@media ($xl) { /* .mob-products $xl */ }
//@media ($xxl) { /* .mob-products $xxl */ }

.mob-products {
  margin-bottom: 26px;

  &__label {
    padding: 12px 42px 12px 16px;
    position: relative;
    font-family: $ff_second;
    font-size: 17px;
    font-weight: 700;
    letter-spacing: 0.5px;
    line-height: 20px;
    cursor: pointer;
  }
  &__label:before {
    position: absolute;
    top: 20px;
    right: 19px;
  }
  &__label.opened:before {
    transform: rotate(-180deg);
  }
  &__link-to-all {
    display: inline-block;
    padding: 0 16px;
    font-family: $ff_third;
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    color: #0F1011;
    cursor: pointer;
    margin-bottom: 6px;
  }
  &__all {
    display: none;
  }
  &__all.opened {
    display: block;
  }
  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  &__list--adding {
    display: none;
  }
  &__list--adding.opened {
    display: block;
  }
  &__item {
    padding: 10px 16px;
  }
  &__item img {
    width: 31px;
    height: auto;
    margin-right: 8px;
  }
  &__link {
    display: flex;
    align-items: flex-start;
  }
  &__link span {
    font-size: 14px;
    line-height: 16px;
    color: #0F1011;
    font-family: $ff_third;
    display: inline-block;
    padding: 4px 0;
    text-decoration: none;
  }
  &__link:hover span {
    text-decoration: underline;
  }
  &__more {
    padding: 10px 16px;
    text-align: center;
  }
  &__more.opened {
    display: none;
  }

  .btn {
    font-size: 16px;
    text-transform: none;
    max-width: 328px;
    padding: 13px 16px;
  }
}