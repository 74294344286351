/* .checkbox */
//@media ($xs) { /* .checkbox $xs */ }
//@media ($sm) { /* .checkbox $sm */ }
//@media ($md) { /* .checkbox $md */ }
//@media ($lg) { /* .checkbox $lg */ }
//@media ($xl) { /* .checkbox $xl */ }
//@media ($xxl) { /* .checkbox $xxl */ }

.checkbox {

  &__input {
    appearance: none;
    position: absolute;
    border: none;
  }

  &__label {
    position: relative;
    font-size: 16px;
    color: #444;
    padding-left: 32px;
    display: inline-block;
    cursor: pointer;

    &:after {
      content: '';
      display: block;
      width: 22px;
      height: 22px;
      border: 1px solid #ccc;
      background-color: #fff;
      border-radius: 2px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }

    &:before {
      position: absolute;
      top: 5px;
      left: 4px;
      z-index: 2;
    }

    .checkbox__input:checked + &:after {
      background-color: #FB540D;
      border: 1px solid #FB540D;
    }

    .checkbox__input:checked + &:before {
      display: block;
    }
  }
}