/* .crumbs */
//@media ($xs) { /* .crumbs $xs */ }
//@media ($sm) { /* .crumbs $sm */ }
//@media ($md) { /* .crumbs $md */ }
@media ($lg) { /* .crumbs $lg */ }
//@media ($xl) { /* .crumbs $xl */ }
//@media ($xxl) { /* .crumbs $xxl */ }

.crumbs {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 16px;

  &__item {
    font-weight: 700;
  }

  &__item--active {
    display: none;
    color: #BAC0C9;

    @media ($lg) {
      display: inline-block;
    }
  }

  &__arrow {
    margin-left: 10px;
    margin-right: 10px;
    display: none;

    @media ($lg) {
      display: inline-block;
    }
  }
}