/* .refill-reminder */
//@media ($xs) { /* .refill-reminder $xs */ }
//@media ($sm) { /* .refill-reminder $sm */ }
@media ($md) { /* .refill-reminder $md */ }
//@media ($lg) { /* .refill-reminder $lg */ }
//@media ($xl) { /* .refill-reminder $xl */ }
//@media ($xxl) { /* .refill-reminder $xxl */ }

.refill-reminder {
  margin-bottom: 40px;

  &__label {
    margin-bottom: 30px;
  }
  &__label:before {
    margin-right: 8px;
  }
  &__row {
    margin-bottom: 32px;
  }
  &__radio-block {
    @media ($md) {
      display: flex;
      align-items: center;
      margin-right: 16px;
    }
  }
  .radio {
    margin-bottom: 24px;

    @media ($md) {
      margin-bottom: 0;
    }
  }
  .radio:first-child {
    margin-right: 32px;
  }
  .radio:last-child {
    margin-bottom: 0;
  }
  .radio__label {
    padding-left: 32px;
  }
  &__select-block {
    padding-left: 95px;
    margin-top: -40px;

    @media ($md) {
      padding-left: 0;
      margin-top: 0;
      display: flex;
      align-items: center;
    }
  }
  &__select-block .form__field {
    @media ($md) {
      margin-bottom: 0;
    }
  }
  &__select-time {
    display: flex;
    margin-left: -95px;

    @media ($md) {
      margin-left: 0;
    }
  }
  &__select-time .form__item {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }
  &__select-time .form__field {
    margin-bottom: 0;

    @media ($md) {
      width: 93px;
    }
  }
  &__select-time .form__select-label {
    width: 44px;
    text-align: right;
    padding: 16px;
  }
  &__select-time .form__item:first-child .form__select-label {
    width: 95px;

    @media ($md) {
      width: auto;
    }
  }
  &__select-days {
    @media ($md) {
      margin-right: 16px;
    }
  }
  &__select-days .form__field--select {
    @media ($md) {
      width: 146px;
    }
  }
  &__control {
    @media ($md) {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
  }
}