/* .phones */
//@media ($xs) { /* .phones $xs */ }
//@media ($sm) { /* .phones $sm */ }
//@media ($md) { /* .phones $md */ }
//@media ($lg) { /* .phones $lg */ }
//@media ($xl) { /* .phones $xl */ }
//@media ($xxl) { /* .phones $xxl */ }

%phones__line {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;

  @media ($lg) {
    margin-bottom: 0;
  }
}

.phones {
  color: #5C6471;
  text-align: center;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 32px;

  @media ($lg) {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 14px;
  }

  &__title {
    @extend %phones__line;
  }

  &__title:before {
    margin-right: 6px;
  }

  &__title-label {
    color: $c_text;
  }

  &__item {
    @extend %phones__line;

    @media ($lg) {
      margin-right: 16px;
    }
  }

  &__item:last-child {
    margin-bottom: 0;
  }
}