/* .qty */
//@media ($xs) { /* .qty $xs */ }
//@media ($sm) { /* .qty $sm */ }
@media ($md) { /* .qty $md */ }
//@media ($lg) { /* .qty $lg */ }
//@media ($xl) { /* .qty $xl */ }
//@media ($xxl) { /* .qty $xxl */ }

.qty {
  border: 1px solid #D2D4D9;
  padding: 0;
  background-color: #fff;
  border-radius: 6px;
  height: 42px;
  width: 140px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  margin-right: 4px;

  @media ($md) {
    margin: auto;
  }

  &__btn {
    border-width: 0;
    width: 40px;
    border-color: #D2D4D9;
    background-color: #EDEEF0;
    cursor: pointer;

    &:hover {
      background-color: #E3E3E3;
    }
  }

  &__btn--decrement {
    font-size: 2px;
    border-right: 1px solid #D2D4D9;
  }

  &__btn--increment {
    font-size: 10px;
    border-left: 1px solid #D2D4D9;
  }

  &__input {
    text-align: center;
    padding: 0;
    width: 62px;
    border: none;
    background-color: transparent;
    outline: none;
  }
}