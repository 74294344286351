/* .payment-info */
//@media ($xs) { /* .payment-info $xs */ }
//@media ($sm) { /* .payment-info $sm */ }
//@media ($md) { /* .payment-info $md */ }
//@media ($lg) { /* .payment-info $lg */ }
//@media ($xl) { /* .payment-info $xl */ }
//@media ($xxl) { /* .payment-info $xxl */ }

.payment-info {
  margin-bottom: 38px;
}