/* .helpers */
//@media ($xs) { /* .helpers $xs */ }
//@media ($sm) { /* .helpers $sm */ }
//@media ($md) { /* .helpers $md */ }
@media ($lg) { /* .helpers $lg */ }
//@media ($xl) { /* .helpers $xl */ }
//@media ($xxl) { /* .helpers $xxl */ }

.t-center {
  text-align: center !important;
}
.t-lg-center {
  @media ($lg) {
    text-align: center !important;
  }
}
.t-lg-right {
  @media ($lg) {
    text-align: right !important;
  }
}
.bgc-EFF5F6 {
  background-color: #EFF5F6 !important;
}
.mb-12 {
  margin-bottom: 12px !important;
}
.mb-32 {
  margin-bottom: 32px !important;
}
.mb-52 {
  margin-bottom: 52px !important;
}
.maw-117 {
  max-width: 117px !important;
}