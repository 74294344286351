/* .description-help */
//@media ($xs) { /* .description-help $xs */ }
//@media ($sm) { /* .description-help $sm */ }
//@media ($md) { /* .description-help $md */ }
//@media ($lg) { /* .description-help $lg */ }
//@media ($xl) { /* .description-help $xl */ }
//@media ($xxl) { /* .description-help $xxl */ }

.description-help {

  @media ($lg) {
    padding-bottom: 96px;
  }

  img {
    width: 232px;
    box-shadow: 8px 11px 0px #c3c8d3, 10px 15px 25px #ddd;
    margin-bottom: 39px;

    @media ($lg) {
      width: 484px;
      box-shadow: 6px -6px 0px #c3c8d3, 10px 15px 25px #ddd;
      margin-bottom: 0;
    }

    @media ($xxl) {
      box-shadow: 16px -6px 0px #c3c8d3, 10px 15px 25px #ddd;
      margin-top: -133px;
    }
  }
}