.icon {
  &:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    background-image: url("../img/#{$spritesheet-image}") !important;
  }

  //&-example {
  //  &:before {
  //    .sprite
  //
  //    ($i-example);
  //  }
  //}

  &-cart {
    &:before {
      @include sprite($icon-cart);
    }
  }
  &-cart-white {
    &:before {
      @include sprite($icon-cart-white);
    }
  }
  &-hamburger {
    &:before {
      @include sprite($icon-hamburger);
    }
  }
  &-times {
    &:before {
      @include sprite($icon-times);
    }
  }
  &-arrow-select {
    &:before {
      @include sprite($icon-arrow-select);
    }
  }
  &-arrow-dropdown {
    &:before {
      @include sprite($icon-arrow-dropdown);
    }
  }
  &-select-marker {
    &:before {
      @include sprite($icon-select-marker);
    }
  }
  &-select-marker-disable {
    &:before {
      @include sprite($icon-select-marker-disable);
    }
  }
  &-advantages-plus {
    &:before {
      @include sprite($icon-advantages-plus);
    }
  }
  &-advantages-minus {
    &:before {
      @include sprite($icon-advantages-minus);
    }
  }
  &-slider-prev {
    &:before {
      @include sprite($icon-slider-prev);
    }
  }
  &-slider-next {
    &:before {
      @include sprite($icon-slider-next);
    }
  }
  &-bonuses {
    &:before {
      @include sprite($icon-bonuses);
    }
  }
  &-plus {
    &:before {
      @include sprite($icon-plus);
    }
  }
  &-minus {
    &:before {
      @include sprite($icon-minus);
    }
  }
  &-remove {
    &:before {
      @include sprite($icon-remove);
    }
  }
  &-upgrade {
    &:before {
      @include sprite($icon-upgrade);
    }
  }
  &-arr-choose-dos {
    &:before {
      @include sprite($icon-arr-choose-dos);
    }
  }
}