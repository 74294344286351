.order {
    background-color: #2F292B;
    color: #FFFFFF;
    text-align: center;
    font-size: 12px;
    line-height: 14px;
    font-family: $ff_fourth;
    padding: 32px 24px;
    margin-bottom: 40px;
    border-radius: 20px;

    @media ($md) {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        padding: 24px;
        margin-bottom: 72px;
    }

    &__desc {
        color: rgba(251, 251, 251, 0.8);

        @media ($md) {
            text-align: left;
            padding-right: 15px;
        }

        @media ($lg) {
            max-width: 200px;
        }
    }

    &__ctrl {
        @media ($lg) {
            display: flex;
            align-items: center;
        }
    }

    &__sum {
        margin-bottom: 20px;
        white-space: nowrap;

        @media ($lg) {
            margin-bottom: 0;
            margin-right: 40px;
        }
    }

    .btn {
        width: 100%;
        max-width: 280px;
        font-size: 20px;
        line-height: 24px;
        padding: 19px 16px;
        margin-bottom: 31px;

        @media ($md) {
            margin-bottom: 0;
        }
    }
}