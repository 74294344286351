/* .soc-notes */
//@media ($xs) { /* .soc-notes $xs */ }
//@media ($sm) { /* .soc-notes $sm */ }
//@media ($md) { /* .soc-notes $md */ }
//@media ($lg) { /* .soc-notes $lg */ }
//@media ($xl) { /* .soc-notes $xl */ }
//@media ($xxl) { /* .soc-notes $xxl */ }

.soc-notes {
  margin-bottom: 20px;

  &__list {
    text-align: center;
  }
}