// COLORS
$c_bgBody: #fff;
$c_text: #202225;
 $c_brand: #028FD0;
 $c_accent: #31D7A5;
// $c_gray_light: #D3D3D3;
// $c_gray_dark: #5C5F65;

// Font-family
$ff_base: 'Lato', sans-serif;
$ff_second: 'HelveticaNeue', sans-serif;
$ff_third: 'Helvetica', sans-serif;
$ff_fourth: 'Open Sans', sans-serif;

// Brakepoints
$xs: "min-width: 480px";
$sm: "min-width: 576px";
$md: "min-width: 768px";
$lg: "min-width: 992px";
$xl: "min-width: 1200px";
$xxl: "min-width: 1400px";

// Bootstrap
// Кількість колонок
// $grid-columns: 12;

// Ширина відступів між колонками
// $grid-gutter-width: 1.5rem;
// $grid-gutter-width: 2rem;
$grid-gutter-width: 16px;

// Контрольні точки
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
    // xxl: 1400px
);

// Максимальні ширини
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1200px
  // xxl: 1320px
);