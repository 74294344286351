/* .types-of-viagra */
//@media ($xs) { /* .types-of-viagra $xs */ }
//@media ($sm) { /* .types-of-viagra $sm */ }
//@media ($md) { /* .types-of-viagra $md */ }
//@media ($lg) { /* .types-of-viagra $lg */ }
//@media ($xl) { /* .types-of-viagra $xl */ }
//@media ($xxl) { /* .types-of-viagra $xxl */ }

.types-of-viagra {
  padding-top: 48px;
  padding-bottom: 28px;

  @media ($lg) {
    padding-top: 96px;
    padding-bottom: 24px;
  }

  &__item {
    margin-bottom: 28px;

    @media ($lg) {
      margin-bottom: 52px;
    }
  }

  p {
    color: #5C6471;
  }

  img {
    width: 328px;
  }
}